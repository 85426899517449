<div class="searchbar">
	<p-inputGroup>
		<input
			pInputText
			type="text"
			placeholder="Suche"
			class="w-full w-30rem"
			[(ngModel)]="inputValue"
			(ngModelChange)="this.inputChanged.next($event)"
			(keydown.enter)="doSearch()"
			(keydown.shift.enter)="doGlobalSearch()"
			(focus)="onFocus()"
		/>

		<button
			*ngIf="inputValue"
			type="button"
			pButton
			icon="pi pi-times"
			class="p-button-text p-button-danger clear-button"
			(click)="inputValue = ''; inputChanged.next('');">
		</button>

		<button
			type="button"
			pButton
			icon="pi pi-search"
			class="search-button"
			(click)="doSearch()">
		</button>

		<p-toggleButton
			styleClass="global-toggle"
			onIcon="pi pi-globe"
			offIcon="pi pi-list"
			[(ngModel)]="isGlobalSearch"
			[pTooltip]="isGlobalSearch ? 'Globale Suche' : 'Lokale Suche'"
		/>
	</p-inputGroup>

	<tb-search-overlay
		*ngIf="isGlobalSearch && dropdownVisible"
		[activeFilters]="activeFilters"
		[searchResults]="searchResults"
		(activeFilterChanged)="activeFilterChanged($event)"
		(onRouteChange)="onRouteChange()"
	/>
</div>